import styled, { css } from 'styled-components';

export const FlexibleDiv = styled.div`
  display: flex;
`;

export const FlexCenterAligned = styled(FlexibleDiv)`
  align-items: center;
  justify-content: center;
`;

export const FlexColumn = styled(FlexibleDiv)`
  flex-direction: column;
`;

export const FlexCenteredColumn = styled(FlexColumn)`
  align-items: center;
`;

export const customScrollbar = trackMargin => css`
  ::-webkit-scrollbar {
    width: 2px;
    display: flex;

    &-track {
      background: #eae5e1;
      border-radius: 10px;
      margin-bottom: ${trackMargin};
    }

    &-thumb {
      background: ${({ theme }) => theme.colors.text.secondary};
      border-radius: 10px;

      &:hover {
        background: #807c75;
      }
    }
  }
`;
