import { css } from 'styled-components';
import { FontWeight } from '../../../styled/constants';

const createTextStyles = ({
  size = '15px',
  weight = FontWeight.thin,
  style = 'normal',
  lineHeight = 'normal',
  letterSpacing = 'normal',
  textDecoration = 'none',
  textTransform = 'none'
}) => css`
  font-size: ${size};
  font-weight: ${weight};
  font-style: ${style};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
  text-decoration-line: ${textDecoration};
  text-transform: ${textTransform};
`;

const headersTagStyles = {
  h1: createTextStyles({ size: '20px' }),
  h2: createTextStyles({ size: '18px', weight: FontWeight.medium }),
  h3: createTextStyles({ size: '16px', weight: FontWeight.medium }),
  h4: createTextStyles({ weight: FontWeight.bold }),
  h5: createTextStyles({ size: '13px', weight: FontWeight.bold })
};

export const tagStyles = {
  ...headersTagStyles,
  header_bold: createTextStyles({ size: '20px', weight: FontWeight.bold }),
  tab: createTextStyles({ size: '8px', textTransform: 'uppercase' }),
  title_1: createTextStyles({ weight: FontWeight.medium }),
  title_2: createTextStyles({ size: '14px' }),
  title_3: createTextStyles({
    size: '14px',
    weight: FontWeight.medium,
    lineHeight: '17px'
  }),
  title_5: createTextStyles({ size: '13px', weight: FontWeight.bold }),
  body_1: createTextStyles({}),
  body_2: createTextStyles({ size: '14px' }),
  p: createTextStyles({ lineHeight: '24px' }),
  caption_1: createTextStyles({ size: '12px' }),
  caption_2: createTextStyles({ size: '11px', weight: FontWeight.medium }),
  caption_4: createTextStyles({ size: '10px', weight: FontWeight.medium }),
  numbers_1: createTextStyles({ size: '24px', weight: FontWeight.medium }),
  numbers_2: createTextStyles({ weight: FontWeight.bold, lineHeight: '17px' }),
  bold: createTextStyles({ weight: FontWeight.bold }),
  numbers_3: createTextStyles({
    weight: FontWeight.medium,
    lineHeight: '19px',
    letterSpacing: '3px'
  }),
  numbers_4: createTextStyles({
    size: '30px',
    style: 'italic',
    weight: FontWeight.medium
  }),
  numbers_5: createTextStyles({
    weight: FontWeight.medium,
    lineHeight: '19px',
    letterSpacing: '2px'
  })
};

export const variantStyles = {
  underlined: createTextStyles({ textDecoration: 'underline' }),
  title_uppercase: createTextStyles({
    size: '14px',
    weight: FontWeight.medium,
    style: 'normal',
    textTransform: 'uppercase'
  }),
  caption_italic: createTextStyles({ size: '10px', style: 'italic' }),
  title_3_linked: createTextStyles({
    size: '14px',
    textDecoration: 'underline'
  })
};
